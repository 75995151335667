import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Containers from "../components/container"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import pagenotfoundStyles from "../styles/pagenotfound.module.css"


export default function Page404() {
  return (
        <Containers>
        <Header />
           <Container fluid="xl" className={pagenotfoundStyles.pagepadding}>
             <Row>
               <Col md={4}></Col>
               <Col md={4}>
                   <h1>Page Not Found</h1>
                   <p>Oop! The Page you are looking for has been removed or relocated</p>
                   <Link to="/">Go Back</Link>
              </Col>
              <Col md={4}></Col>
             </Row>
           </Container>
        <Footer />
        </Containers>
    );
}